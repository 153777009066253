<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
        <div class='table-search'>

          <el-form :model='searchCondition' inline size='mini'>

            <el-form-item label=''>
              <template slot='label'>
                <el-link class='el-icon-refresh' @click='getOptions'>模块名称</el-link>
              </template>

              <el-select v-model='searchCondition.module_name' placeholder='模块名称' allow-create filterable clearable
                         style='width: 300px'>
                <el-option v-for='(label,value) in options' :key='value' :value='value' :label='label'>{{ label }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label='创建时间'>
              <el-date-picker
                v-model='searchCondition.date_range'
                type='daterange'
                align='right'
                unlink-panels
                range-separator='至'
                start-placeholder='开始日期'
                end-placeholder='结束日期'
                value-format='yyyy-MM-dd'
                :picker-options='pickerOptions'>
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' @click='getList'>搜索</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class='default-table'>
          <!--    渲染表格-->
          <m-table :showIndex='true' :offset='offset' max-height='800' :tableData='dataList' :columns='columns'
                   :loading='loadingStatus' @selection-change='handleSelectionChange' border>
          </m-table>
          <!--    渲染分页-->
          <m-pagination :total.sync='total' :limit.sync='pagingData.page_size' :page.sync='pagingData.current_page'
                        @pagination='getList' />
        </div>
      </div>

    </page-header-layout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'

export default {
  name: 'OperationLogList',
  components: { PageHeaderLayout },
  data() {
    return {
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 是否显示弹出层
      open: false,
      // 表单参数
      form: {},
      loadingStatus: false,
      searchCondition: { deal_status: null, event_type: null, date_range: [] },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      columns: [
        {
          title: '日志编号',
          value: 'id',
          width: 80
        },
        // {
        //   title: '数据ID',
        //   value: 'info_id',
        //   width: 120
        // },
        {
          title: '服务名称',
          value: '_module_name',
          width: 120
        },
        {
          title: '日志内容',
          value: 'content',
          width: 400,
          header_align:'center',
          align:'left'
        },
        {
          title: '创建时间',
          value: 'created_at',
          width: 120
        },
        {
          title: '修改时间',
          value: 'updated_at',
          width: 120
        }
      ],
      dataList: [],
      total: 0,
      offset: 0,
      pagingData: {
        page_size: 15,
        current_page: 1
      },
      eventTypeArr: ['check_url', 'user_modify_org'],
      dealStatusMap: { 1: '待处理', 2: '处理中', 3: '成功', 4: '失败' },
      currRow: {},
      dialogVisible: false,
      options: []
    }
  },
  methods: {
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      if (this.searchCondition.module_name) {
        condition.module_name = this.searchCondition.module_name
      }

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }
      return condition
    },
    async getList() {
      this.loadingStatus = true
      let searchCondition = this.handleSearchCondition()
      // 合并
      Object.assign(searchCondition, this.pagingData)
      let { list, pages } = await this.$api.getOperationLogList(searchCondition)
      // list.map((item) => {
      //   item['deal_status_alias'] = this.dealStatusMap[item.deal_status]
      // })
      this.$nextTick(() => {
        this.dataList = list
        this.total = pages.total
        this.offset = pages.offset
        this.pagingData.current_page = pages.current_page
        this.pagingData.page_size = pages.page_size
        this.loadingStatus = false
      })
    },

    async getOptions() {
      let { options } = await this.$api.getOperationLogOptions()
      this.options = options
    }
  },
  created() {
    this.getOptions()
    this.getList()
  }
}
</script>

<style scoped>

</style>